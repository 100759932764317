// General settings
.section {

	@include font-size(22px);
	
	position: relative;

	img {

		max-width: 100%;
		display: block;
	}

	.content--wrapper {

		display: flex;
		flex-direction: column;

		@include bp($bp-nexus) {

			flex-direction: row;
		}
	}

	.line {

		position: absolute;
		left: 0;
		width: 100%;
		height: 1px;
		z-index: 1000;
		top: 0;

		@include bp($bp-nexus) {

			padding-top: percentage(($col_width - 30)/($col_width * 6));
		}

		&:after, &:before {

			content: '';
			display: none;
			height: 1px;
			position: absolute;
			background-color: $blue;

			@include bp($bp-ipad-portrait) {

				display: block;
			}
		}

		&:before {

			width: calculateRem(70px);
		}

		&:after {

			width: calculateRem(50px);
		}
	}

	.image {

		width: 100%;
		background-size: cover;
		background-position: 50%;
		min-height: 450px;
		position: relative;

		@include bp($bp-nexus) {

			width: 50%;
		}

		.line {

			&:after {

				display: none;
			}
		}
	}

	.content {

		width: 100%;
		padding: 35px 20px 50px;
		text-align: left;
		transition: all 0.3s ease-in;
		position: relative;

		@include bp($bp-nexus) {

			width: 50%;
			padding-top: percentage(($col_width - 60) /$max_width);
			padding-bottom: percentage(($col_width - 40) /$max_width);
			padding-left: percentage(($col_width - 80)/$max_width);
			padding-right: percentage(($col_width - 80)/$max_width);
		}

		@include bp($bp-ipad-portrait) {

			padding-left: percentage(($col_width - 60)/$max_width);
			padding-right: percentage(($col_width - 60)/$max_width);
		}

		@include bp($bp-laptop) {

			padding-left: percentage(($col_width + 20)/$max_width);
			padding-right: percentage(($col_width - 40)/$max_width);
		}

		h2 {

			@include font-size(35px);

			font-weight: 600;
			line-height: calculateRem(55px);
			color: $blue;
			margin: 0 0 calculateRem(60px);
			padding: 0;
		}

		ul {

			list-style: none;
			margin: 0;
			padding: 0;
			margin-bottom: calculateRem(20px);

			@include bp($bp-nexus) {
				
				margin-bottom: calculateRem(40px);
			}
		}

		li {

			position: relative;
			padding-left: calculateRem(40px);
			margin-bottom: 0;
		}

		p {

			margin: 0;
		}

		.icon {

			display: block;
			width: calculateRem(22px);
			height: calculateRem(22px);
			position: absolute;
			left: 0;
			top: 50%;
			color: $black;
			transform: translateY(-50%);
		}
	}

	.pipe-image {

		position: absolute;
		z-index: 10000;
	}
}



// Homepage Feature
.section--feature {

	min-height: 300px;
	transition: all 0.3s ease-in;
	background-size: cover;
	background-position: 50%;
	height: 15px;
	padding-top: percentage(750 / $max_width);
}



// Homepage Intro
.section--intro {

	@include font-size(30px);

	background-color: $yellow;
	color: $blue;

	@include bp($bp-nexus) {

		@include font-size(40px);		
	}

	.content {

		margin-left: auto;
		width: 100%;
		padding: 80px 40px 50px;
		font-weight: 600;
		text-align: center;

		@include bp($bp-nexus) {

			text-align: left;
			padding-left: percentage((($col_width * 4) - 30)/$max_width);
			padding-right: percentage(($col_width + $offset)/$max_width);
			padding-bottom: percentage(($col_width + 20)/$max_width);
		}

		.color--white {

			display: block;
		}

		&:after, &:before {

			display: none;
		}
	}

	.more {

		@include font-size(24px);

		line-height: calculateRem(20px);
		transition: all 0.3 ease-in-out;
		color: $blue;
		text-decoration: underline;
		display: block;	
		vertical-align: middle;
		height: calculateRem(30px);
		margin-top: calculateRem(60px);

		@include bp($bp-nexus) {

			@include font-size(20px);

			margin-left: calculateRem(30px); 
			display: inline-block;
			margin-top: 0;
		}

		&:hover {

			text-decoration: none;
		}
	}

	.pipe-image {

		transform: translateX(-50%);
		width: calculateRem(80px);
		height: calculateRem(40px);
		min-width: 80px;
		left: 50%;
		top: calculateRem(40px);
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			left: percentage( ( $col_width + $offset ) / $max_width );
			top: calculateRem(90px);
			transform: translateX(0);
		}


		.icon {

			color: $white;
			width: 100%;
			height: 100%;
			display: block;
		}
	}
}



.section--services-1 {

	.content--wrapper {

		// flex-direction: row-reverse;

		@include bp($bp-nexus) {

			flex-direction: row-reverse;
		}
	}

	.content {

		.line {

			&:before {

				right: 0;
			}

			&:after {

				left: 0;
			}
		}
	}

	.image {

		.line {

			&:before {

				left: 0;
			}

			&:after {

				right: 0;
			}
		}
	}
}

.section--services-2 {

	background-color: $blue;
	color: $yellow;

	.line {

		&:before, &:after {

			background-color: $yellow;
		}
	}

	.content {
		
		width: 100%;
		padding-top: calculateRem(100px);

		@include bp($bp-nexus) {

			width: percentage((($col_width * 6) - 50)/ $max_width);
			padding-top: percentage(($col_width - 60)/$max_width);
		}

		h2 {

			color: $yellow;	
		}
		
		.icon {

			color: $yellow;
		}

		.line {
			
			padding-top: percentage(($col_width - 30)/(($col_width * 6) - 50));

			&:before {

				left: 0;
			}

			&:after {

				right: 0;
			}
		}
	}

	.image {

		width: 100%;
		
		@include bp($bp-nexus) {

			width: percentage((($col_width * 6) + 50)/ $max_width);
		}

		.line {
			
			padding-top: percentage(($col_width - 30)/(($col_width * 6) + 50));

			&:before {

				right: 0;
			}

			&:after {

				left: 0;
			}
		}
	}

	.company--logos {

		display: flex;
		flex-direction: row;

		li {

			width: 33.33%;
			text-align: center;
			padding: 0;

			img {

				display: inline-block;
			}
		}
	}
}



.section--clients {

	.content--wrapper {

		@include bp($bp-nexus) {

			flex-direction: row-reverse;
		}
	}

	.image {

		height: 1px;
		padding-top: percentage(750/$max_width);
	}

	.content {

		ul {

			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;

			@include bp($bp-nexus) {

				justify-content: space-between;
			}
		}

		li {

			width: 50%;
			margin: calculateRem(40px) 0 calculateRem(50px);
			text-align: center;
			padding: 0 calculateRem(25px);
			// max-height: calculateRem(58px);

			@include bp($bp-nexus) {

				width: 33.33%;
				padding: 0 calculateRem(10px);
			}

			img {

				display: inline-block;
				vertical-align: bottom;
				width: auto;
			}
		}
	}
}



.section--quote {

	background-color: $yellow;

	.pipe-image {

		width: percentage( 187 / $max_width );
		transform: translateY(20%);
		position: absolute;
		right: percentage( 122 / $max_width );
		transition: all 0.3s ease-in;

		@include bp($bp-nexus) {

			transform: translateY(-43%);
		}
	}

	.content {

		width: 100%;
		padding: 50px 40px 50px;

		@include bp($bp-nexus) {

			padding-left: percentage(($col_width + $offset)/$max_width );
			padding-right: percentage(($col_width + $offset)/$max_width );
			padding-top: percentage(($offset)/$max_width );
			padding-bottom: percentage((($offset * 2) + 20)/$max_width );			
		}



		h2 {

			@include font-size(50px);
			@include line-height(1.3);

			color: $white;
			margin-bottom: calculateRem(60px);

			@include bp($bp-nexus) {

				@include font-size(110px);
				@include line-height(0.9);

				margin-bottom: calculateRem(140px);
			}
		}
	}
}





