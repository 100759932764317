// /*---------------------------------------------*\
// 	# Header
// \*---------------------------------------------*/
header {

	max-width: #{$max_width}px;
	margin: 0 auto;
	background-color: $white;
	position: relative;

	.content__wrapper {

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 20px 30px 20px 15px;
		transition: all 0.3s ease-in-out;

		@include bp($bp-nexus) {

			padding: percentage($offset/$max_width) percentage(($col_width + $offset)/$max_width) percentage(($offset + 20)/$max_width);
		}

		&:before, &:after {

			content: '';
			width: calculateRem(80px);
			height: calculateRem(35px);
			display: none;
			position: absolute;
			background-image: url('/img/rabbit.svg');
			background-size: cover;
			background-repeat: no-repeat;

			@include bp($bp-ipad-portrait) {

				display: block;
			}
		}

		&:before {

			left: 0;
			background-position: -40px 0;
		}

		&:after {

			right: 0;
			background-position: 40px 0;
		}
	}

	.phone {

		@include font-size(26px);

		color: $blue;
		text-decoration: none;
		font-weight: 600;

		.icon {

			display: inline-block;
			margin-right: 0;
			width: calculateRem(45px);
			height: calculateRem(45px);
			transition: all 0.3s ease-in-out;
			color: $blue;

			@include bp($bp-nexus) {

				width: calculateRem(20px);
				height: calculateRem(20px);
				margin-right: calculateRem(20px);
			}
		}

		span {

			display: none;

			@include bp($bp-nexus) {

				display: inline;
			}
		}
	}

	.tagline {

		@include font-size(110px);
		
		line-height: calculateRem(90px);
		width: 100%;
		text-align: left;
		padding-top: percentage(($offset + 20)/$max_container_width);
		color: $blue;

		// @include bp($bp-nexus) {

		// 	line-height: calculateRem(52px);
		// }

		.color {

			display: block;
			font-weight: 600;
		}
	}

	.rabbit {

		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		.icon {
			width: calculateRem(80px);
			height: calculateRem(35px);			
		}
	}
}





