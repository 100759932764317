/*---------------------------------------------*\
	# Footer
\*---------------------------------------------*/
footer {

	position: relative;

	.content--wrapper {

		display: flex;
		flex-direction: column-reverse;
		align-items: flex-start;
		position: relative;
		text-align: center;
		padding: 50px 20px 70px;

		@include bp($bp-nexus) {

			padding-left: percentage(($col_width + $offset)/$max_width );
			padding-right: percentage(($col_width + $offset)/$max_width );
			padding-top: percentage(($offset * 2)/$max_width );
			padding-bottom: percentage((($offset * 2) + 20)/$max_width );
		}
	}

	.content {

		@include font-size(22px);
		
		width: 100%;
		position: relative;
		color: $blue;
		// margin-bottom: calculateRem(60px);

		@include bp($bp-nexus) {

			@include font-size(20px);

			min-height: calculateRem(375px);
		}

		* {

			position: static;
			display: block;
			text-align: center;
			margin-bottom: 20px; 

			@include bp($bp-nexus) {

				display: inline-block;
				position: absolute;
				margin-bottom: 0;
			}

			& > * {

				position: static;
				display: inline-block;
			}
		}
	}

	a {

		text-decoration: none;
		color: $blue;
	}

	.copyrights {

		bottom: 0;
		left: 0;
	}

	.credits {

		bottom: 0;
		right: 0;
		margin-bottom: 0;
	}

	.telephone {

		top: 0;
		left: 0;
	}

	.email {

		top: 0;
		right: 0;
	}

	.address {

		@include bp($bp-nexus) {

			left: 50%;
			transform: translateX(-50%);
		}
	}
}





