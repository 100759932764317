.navigation--wrapper {

	position: fixed;
	top: calculateRem(60px);
	z-index: 10000;
	width: 100%;
	left: 0;
	display: none;
	transition: top 0.3s ease-in-out;

	@include bp($bp-nexus) {

		display: block;
	}

	@include bp($bp-ipad-portrait) {

		top: calculateRem(100px);
	}

	&.moved {

		top: calculateRem(-40px);
	}

	.navigation {

		max-width: #{$max_width}px;
		margin: 0 auto;
		position: relative;
		height: calculateRem(300px);
	}

	ul {

		list-style: none;
		margin: 0;
		padding: 0;
		text-align: right;
		display: inline-block;
		position: absolute;
		right: percentage(($col_width + $offset)/$max_width);
		bottom: calculateRem(50px);
	}


	a {

		@include font-size(20px);
		@include line-height(1);
		font-weight: 600;
		text-decoration: none;
		padding: 7px 40px 10px 18px;
		display: inline-block;
		border-radius: 20px;
		position: relative;

		span {

			color: $blue;
		}

		&:after {

			content: '';
			display: block;
			background-color: $yellow;
			border-radius: 50%;
			width: calculateRem(10px);
			height: calculateRem(10px);
			position: absolute;
			top: 50%;
			right: 10px;
			transform: translateY(-50%);
		}
	}

	li {

		margin-bottom: 5px;
	}

	li.active {

		a {

			background-color: rgba($white, 1);
		}

	}
}