// Regular 			- 400 normal
// Italic 			- 400 italic
// Medium 			- 500 normal 
// Medium Italic 	- 500 italic
// Semi Bold 		- 600 normal
// Semi Bold italic - 600 italic
// Bold 			- 700 normal
// Bold Italic 		- 700 italic


.html {

	@include line-height(1.4);

	font-size: $base_font_size - 6; // 18px
	font-weight: 400;

	@include bp($bp-iphone-portrait) {

		font-size: $base_font_size - 5; // 17px
	}

	@include bp($bp-nexus) {
		
		font-size: $base_font_size - 4; // 18px
	}

	@include bp($bp-ipad-landscape) {

		font-size: $base_font_size - 2; // 20px
	}

	@include bp($bp-laptop) {

		font-size: $base_font_size; // 22px
	}
}

 



