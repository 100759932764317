form {

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;

	.item {

		
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-bottom:0;

		@include bp($bp-nexus) {

			width: percentage( (($col_width * 5) + 30) / $max_container_width);
			margin-bottom: calculateRem(35px);
		}
	}

	.item--textarea {

		width: 100%;

		@include bp($bp-nexus) {

			width: percentage( ($col_width * 4) / $max_container_width);
		}
	}

	input[type="text"], input[type="email"] {

		width: 100%;
		height: calculateRem(80px);
    	margin-bottom: calculateRem(35px);

		@include bp($bp-nexus) {

			width: percentage( 400/ (($col_width * 5) + 30));
			margin-bottom: 0;
		}
	}

	input[type="text"]:first-of-type {
		
		margin-bottom: calculateRem(35px);
	}

	textarea {

		width: 100%;
    	margin-bottom: calculateRem(35px);

		@include bp($bp-nexus) {

			margin-bottom: 0;
		}
	}

	button[type="submit"] {

		height: calculateRem(80px);
		padding: calculateRem(25px);
		border: 0;
		background-color: $white;
		color: $black;
		margin-left: auto;
		width: 100%;
		position: relative;
		text-align: left;

		@include bp($bp-iphone-landscape) {

			width: calculateRem(270px);
		}

		&:after {

			content: '';
			position: absolute;
			display: block;
			right: calculateRem(-45px);
			top: 50%;
			transform: translateY(-50%);


			width: calculateRem(90px);
			height: calculateRem(40px);
			background-image: url('/img/rabbit.svg');
			background-size: cover;
			background-repeat: no-repeat;
		}
	}
}